import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function IndexPage() {
  return (
    <Layout hero={<Hero />} className="homepage">
      <SEO title="Home" />
      <div className="wrapper">
        <div className="container">
          <div className="row">
            <section className="col-6 col-12-narrower feature">
              <div className="image-wrapper first">
                <div className="image featured first">
                  <Link to="/loesung/">
                    <Image name="carbon" />
                  </Link>
                </div>
              </div>
              <header>
                <h2>Lösung</h2>
              </header>
              <p>Die Karbonisierungs – Technologie ist ein wichtiger Baustein bei der
              Bewältigung dieser kausalen Herausforderungen. Außer einer konsequenten
              Ressourcenschonung gibt es derzeit kein aussichtsreicheres Lösungskonzept
              für diesen Themenkomplex.</p>
              <ul className="actions">
                <li><Link to="/loesung/" className="button">weiterlesen</Link></li>
              </ul>
            </section>
            <section className="col-6 col-12-narrower feature">
              <div className="image-wrapper first">
                <div className="image featured first">
                  <Link to="/ueber-uns/">
                    <Image name="iceCliff" />
                  </Link>
                </div>
              </div>
              <header>
                <h2>Unser Anspruch</h2>
              </header>
              <p>Unser Anspruch ist es, unseren Kunden aus der Industrie, dem kommunalen Bereich
                sowie der Land- und Forstwirtschaft zukunftsweisende Technologien auf dem Gebiet
                der Karbonisierung, die sich in Deutschland und in anderen Ländern bereits vielfach
                bewähren, zu bieten. Durch geschlossene Wertstoffkreisläufe tragen die von uns
                angewandten ökonomisch und ökologisch sinnvollen Verfahren zur Reduzierung von
                CO<sub>2</sub>  Emissionen (verbesserter CO<sub>2</sub> Footprint) bei.
              </p>
              <ul className="actions">
                <li><Link to="/unser-anspruch/" className="button">weiterlesen</Link></li>
              </ul>
            </section>
          </div>
        </div>
      </div>

      <div id="promo-wrapper">
        <h2>Leistungen</h2>

        <div className="row features">
          <section className="col-4 col-12-narrower feature">
            <h3>Finanzierung</h3>
            <p>
              Um Projekte in die Tat umzusetzen ist in den meisten Fällen nicht wenig
              Kapital notwendig. Hierfür entwickeln wir individuelle Finanzierungslösungen.
            </p>
          </section>
          <section className="col-4 col-12-narrower feature">
            <h3>Projektentwicklung</h3>
            <p>
              <strong>TRV</strong> steht für <strong>T</strong>hermische <strong>R</strong>eststoff <strong>V</strong>erwertung. Als Entwickler von Projekten zur Erzeugung von Karbonisaten aus biogenen Reststoffen sind wir neben der Produktion von Biokohle aus pflanzlichen Reststoffen insbesondere Lösungsanbieter für Kommunen zur Verwertung von Klärschlamm.
            </p>
          </section>
          <section className="col-4 col-12-narrower feature">
            <h3>Betrieb</h3>
            <p>
              Den Betrieb der technischen Anlagen für die von uns entwickelten
              Projekte nehmen wir zusammen mit dem Hersteller der Anlagen selbst in die Hand.
            </p>
          </section>
        </div>
      </div>
    </Layout>
  )
}

function Hero() {
  return (
    <>
      <header>
        <h2>TRV Projektentwicklung UG</h2>
      </header>
      <p>
        Klimaschutz, Ressourcenschonung, Verhinderung der Bodendesertifikation
        <br /> und Grundwasserschutz sind die großen Herausforderungen des 21.
        Jahrhunderts.
      </p>
    </>
  )
}

function Image({ name }) {
  const result = useStaticQuery(graphql`
    query {
      carbon: file(relativePath: { eq: "brian-patrick-tagalog-676639-unsplash.jpg" }) {
        childImageSharp { fluid(maxWidth: 800) { ...GatsbyImageSharpFluid } }
      }

      iceCliff: file(relativePath: { eq: "agustin-lautaro-102390-unsplash.jpg" }) {
        childImageSharp { fluid(maxWidth: 800) { ...GatsbyImageSharpFluid } }
      }
    }
  `)

  return <Img fluid={result[name].childImageSharp.fluid} />
}
